import React, {Component} from 'react';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'
import ContactForm from './components/ContactForm'


class LavoraConNoi extends Component {
  
  render(){
    
    return <div className="Contacts">      
      <Header cpage="Lavora Con Noi" />
      <main role="main">

      <div className="mainConent">

        <div className="content-left-col">
          <p className="lead">
          Siamo sempre alla ricerca di personale giovane e dinamico da inserire nel nostro staff tecnico nel ruolo di <strong>meccanici</strong>, <strong>programmatori</strong> ed <strong>elettronici</strong>.
          </p>
          
          <ContactForm formType="Job"></ContactForm>
          
          
        </div>

        <div className="content-right-col">
          <h2>Gallery</h2>
          <div className="side_image_container">
            <img src="images/pages/staff_1.jpg" className="side_image rounded img-thumbnail" alt="staff"></img>
          </div>
          <div className="side_image_caption">
            Unisciti al nostro team
          </div>

          <div className="side_image_container">
            <img src="images/pages/staff_2.jpg" className="side_image rounded img-thumbnail" alt="staff"></img>
          </div>
          <div className="side_image_caption">
            Un team affiatato è spesso sinonimo di successo
          </div>
        </div>

        <hr className="featurette-divider"></hr>   

      </div>

      <Footer></Footer>

    </main>
  </div>
  
  }
}

export default LavoraConNoi;
