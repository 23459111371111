import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class DMModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "props": props,
            "messages": {
                "close": { "title": "", "body": ""},
                "success": { "title": "Grazie per averci contattato", "body": "La tua richiesta è stata inviata con successo! Grazie per la tua mail. Ti ricontatteremo al più presto."},
                "missingPrivacy": { "title": "Modulo non copleto", "body": "Attenzione! E' necessario accettare il trattamento dei dati per inviare il modulo."},
                "missingFields": { "title": "Modulo non copleto", "body": "Attenzione! Per inviare il modulo devi compilare anche "},
            }
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({ "props": { "show": newProps.show, "modaltype": newProps.modaltype, "fields": newProps.fields } })
        console.log(newProps)
    }
    hideModal = () => {
        this.setState({ "props": { "show": false } })
    }

    render() {

        console.log(this.state.props)
        return <Modal
            {...this.state.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {this.state.messages[this.props.modaltype].title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                {`${this.state.messages[this.props.modaltype].body}${this.state.props.fields}`}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.hideModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}


export default DMModal;