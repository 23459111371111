import React, {Component} from 'react';

import './App.css';
import Header from './components/Header';
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'


class Contatti extends Component {
  
  render(){
    
    return <div className="Contacts">      
      <Header cpage="Contatti" />
      <main role="main">

      <div className="mainConent">

        <div className="content-left-col">
          <p className="lead">
            Utilizza il modulo seguente per entrare in contatto diretto con noi.
          </p>
          <p>
            Ragione Sociale e Mail sono obbligatori per permetterci di contattarti. Ti preghiamo tuttavia di compilare tutti i campi per permetterci un ricontatto più efficace e rapido.
          </p>
                     
          <ContactForm formType="contact"></ContactForm>
          
        </div>

        <div className="content-right-col">
          <h2>Gallery</h2>
          <div className="side_image_container">
            <img src="images/pages/sede_esterno.jpg" className="side_image rounded img-thumbnail" alt="La nostra nuova sede di Mezzago"></img>
          </div>
          <div className="side_image_caption">
            La nostra nuova sede di Mezzago
          </div>

          <div className="side_image_container">
            <img src="images/pages/sede_interno.jpg" className="side_image rounded img-thumbnail" alt="La nostra nuova sede di Mezzago"></img>
          </div>
          <div className="side_image_caption">
            
          </div>
        </div>

        <hr className="featurette-divider"></hr>   

      </div>

      <Footer></Footer>

    </main>
  </div>
  
  }
}

export default Contatti;
