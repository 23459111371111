import React, {Component} from 'react'

class Title extends Component {
    slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w-]+/g, '')       // Remove all non-word chars
            .replace(/--+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }
    render() {
        const cpage = this.props.cpage;
        const classname = `title ${this.slugify(cpage)}`
        return <div className={classname}>
            <h1>{cpage}</h1>
        </div>
    }
}
export default Title;