import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Site pages   
import HomePage from './HomePage';
import Servizi from './Servizi'
import D3Link from './D3Link'
import Contatti from './Contatti';
import Notfound from './NotFound';
import LavoraConNoi from './LavoraConNoi';

export default function App() {

  console.log(`env: ${process.env.NODE_ENV}`)
  console.log(`NODE_ENV: ${process.env.NODE_ENV}`)
  console.log(`mailer: ${process.env.REACT_APP_NODE_MAILER_SERVICEURL}`)
  
  return (
    <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/servizi" component={Servizi} />
        <Route path="/d3link" component={D3Link} />
        <Route path="/contatti" component={Contatti} />
        <Route path="/lavora-con-noi" component={LavoraConNoi} />
        <Route component={Notfound} />
    </Switch>
  )
}