import React, {Component} from 'react';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'

import { faFilePdf, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class D3Link extends Component {
  
  render(){
    
    return <div className="Contacts">      
      <Header cpage="D3Link" />
      <main role="main">

      <div className="mainConent">

        <div className="content-left-col">
          <p className="lead">
          Viste le esigenze di molti clienti ci siamo preoccupati di sviluppare un software che rendesse molto
          semplice l’operazione di trasferimento dati via seriale. 
          </p>
          <h2>Caratteristiche</h2>
          <p>
          L’interfaccia molto intuitiva e semplificata nasconde
          le grandi potenzialità di questo strumento rendendolo compatibile con tutti i sistemi Windows e
          tutti i CN di nuova e vecchia generazione. 
          </p>
          <p>
            Il punto di forza è la stabilità durante il le operazioni di
            trasferimento. Non lasciando spazio a sovrapposizione di funzioni lo rendono idoneo anche laddove, i
            classici sistemi seriali, tendono a perdere dati non rendendo possibile l’utilizzo di SO superiore a windows 98.
            <br/>
            Adatto non solo al trasferimento programmi è utilizzato molto anche dai service per il caricamento dati di
          sistema.
          </p>
          <h2>Screenshot</h2>
          
          <div className="main_image_container">
            <img className="rounded img-thumbnail" src="images/D3Link/screen1Big.gif" alt="La schermata di apertura del programma." />
          </div>
          <div className="main_image_caption">
            La schermata di apertura del programma.
          </div>

          <div className="main_image_container">
            <img className="rounded img-thumbnail" src="images/D3Link/screen2Big.gif" alt="Il pannello che semplifica la configurazione di D3Link." />
          </div>
          <div className="main_image_caption">
            Il pannello che semplifica la configurazione di D3Link.
          </div>

          <div className="main_image_container">
            <img className="rounded img-thumbnail" src="images/D3Link/screen3Big.gif" alt="D3Link all'opera: la finestra di trasmissione dati." />
          </div>
          <div className="main_image_caption">
            D3Link all'opera: la finestra di trasmissione dati.
          </div>
          
        </div>

        <div className="content-right-col">
          <h2>Downloads</h2>
          <p>
            Sono disponibili il download gratuito del programma e la documentazione d'uso:
            <br/>
            <div className="card downloads">
              <div className="card-body">
                <h5 className="card-title"><FontAwesomeIcon size="3x" color="#aaaaaa" icon={faFilePdf} /></h5>
                <p className="card-text">Puoi scaricare ed installare liberamente il nostro software D3 Link.</p>
                <a href="downloads/d3link.zip" className="btn btn-primary">Scarica D3Link</a>
              </div>
            </div>

            <div className="card downloads">
              <div className="card-body">
                <h5 className="card-title"><FontAwesomeIcon size="3x" color="#aaaaaa"icon={faDownload} /></h5>
                <p className="card-text">Installare ed utilizzare D3 Link è estremamente semplice. Tuttavia nella Manuale d'uso potrai trovare le risposte per ogni eventuale dubbio.</p>
                <a href="downloads/d3link.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Scarica il Manuale d'uso</a>
              </div>
            </div>
          </p>
        </div>

        <hr className="featurette-divider"></hr>   

      </div>

      <Footer></Footer>

    </main>
  </div>
  
  }
}

export default D3Link;