import React, {Component} from 'react'
import {Carousel} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import LinkButton from './LinkButton'

class Slider extends Component {

    render() {
        return <Carousel className="carousel">
        <Carousel.Item>
          <img
            sizes="(max-width: 2600px) 100vw, 2600px"
            srcSet="
                  images/responsive/SedeEsterno/sede_esterno_300.jpg 310w,
                  images/responsive/SedeEsterno/sede_esterno_600.jpg 640w,
                  images/responsive/SedeEsterno/sede_esterno_900.jpg 964w,
                  images/responsive/SedeEsterno/sede_esterno_1360.jpg 1405w,
                  images/responsive/SedeEsterno/sede_esterno_1700.jpg 1710w,
                  images/responsive/SedeEsterno/sede_esterno_2100.jpg 1993w,
                  images/responsive/SedeEsterno/sede_esterno_2600.jpg 2508w"
            src="images/responsive/SedeEsterno/sede_esterno_2600.jpg"
            alt="" />
            <Carousel.Caption className="carouselCaption">
              <h3>Noi siamo DM Srl</h3>
              <p>Assistenza e installazione macchine c.n.c.</p>
              <p><LinkButton className="btn btn-primary btn-lg" to='/servizi'><FontAwesomeIcon icon={faBookmark} />&nbsp;Servizi</LinkButton></p>
              
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            sizes="(max-width: 2600px) 100vw, 2600px"
            srcSet="
                  images/responsive/Rack/Rack.jpg_300.jpg 310w,
                  images/responsive/Rack/Rack.jpg_600.jpg 640w,
                  images/responsive/Rack/Rack.jpg_900.jpg 964w,
                  images/responsive/Rack/Rack.jpg_1360.jpg 1405w,
                  images/responsive/Rack/Rack.jpg_1700.jpg 1710w,
                  images/responsive/Rack/Rack.jpg_2100.jpg 1993w,
                  images/responsive/Rack/Rack.jpg_2600.jpg 2508w"
            src="images/responsive/Rack/Rack.jpg_2600.jpg"
            alt="" />
            <Carousel.Caption className="carouselCaption">
              <h3>D3 Link</h3>
              <p>Scarica gratuitamente il nostro software.</p>
              <p><LinkButton className="btn btn-primary btn-lg" to='/d3link'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;Scarica</LinkButton></p>
            </Carousel.Caption>
        </Carousel.Item>
          <Carousel.Item>
            <img
              sizes="(max-width: 2600px) 100vw, 2600px"
              srcSet="
                    images/responsive/buttons/buttons.jpg_300.jpg 310w,
                    images/responsive/buttons/buttons.jpg_600.jpg 640w,
                    images/responsive/buttons/buttons.jpg_900.jpg 964w,
                    images/responsive/buttons/buttons.jpg_1360.jpg 1405w,
                    images/responsive/buttons/buttons.jpg_1700.jpg 1710w,
                    images/responsive/buttons/buttons.jpg_2100.jpg 1993w,
                    images/responsive/buttons/buttons.jpg_2600.jpg 2508w"
              src="images/responsive/buttons/buttons.jpg_2600.jpg"
              alt="" />
              <Carousel.Caption className="carouselCaption">
                <h3>Lavora con DM</h3>
                <p>Il nostro team è sempre in crescita. Forse cerchiamo proprio te!</p>
                <p><LinkButton className="btn btn-primary btn-lg" to='/lavora-con-noi'><FontAwesomeIcon icon={faCheckCircle} />&nbsp;Candidati</LinkButton></p>
              </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
    }
}

export default Slider;