import React from 'react';
import './App.css';
import Header from './components/Header';
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'

function HomePage() {
  return (
    <div className="HomePage">      
      <Header cpage="home" />
      <main role="main">

      <div className="mainConent">

        <div className="content-left-col">
          <h2>Assistenza tecnica cnc</h2>
          <p className="lead">
          La DM s.r.l. è una società che nasce nel 2001 per fornire un servizio di assistenza tecnica CNC competente
          ai clienti più esigenti. 
          <br/>
          Reduci da esperienze presso grandi case costruttrici leader del settore si è voluto
          proporre un servizio diverso, attento alle richieste e necessità del settore atte a diminuire i tempi di fermo
          macchina.
          </p>

          <p>
          Negli anni ci si è specializzati ulteriormente su diversi tipi di macchinari riuscendo ad abbattere i costi per la
          clientela aumentando la qualità del lavoro svolto anche grazie ad attrezzature e sistemi di diagnosi
          all’avanguardia. 
          <br/>
          Questo ci ha permesso di crescere notevolmente per far fronte alla richiesta crescente dei
          nostri assistiti che hanno riposto in noi la meritata fiducia.
          </p>

          <h2>Vision</h2>
          <p>
          Il punto di forza del nostro successo si fonda sulla puntualità onesta dell’aiuto che possiamo fornire con
          passione e competenza. I continui corsi di aggiornamento e l’impegno del nostro numeroso staff, ogni
          giorno ci affermano da anni sul mercato dandoci molta soddisfazione.
          <br/>
          Siamo in grado di soddisfare ogni richiesta del cliente per riparazioni ordinarie e straordinarie sia su
          componenti meccanici che elettronici o programmazioni a bordo macchina, in tempi celeri.
          </p>

          <h2>I nostri partner</h2>
          <div className="partnerImagesContainer">
            <div><img src="images/partners/COMAF_logo.png" className="rounded img-thumbnail logo-partner" alt="Comaf Logo"/></div>
            <div><img src="images/partners/tecnor_macchine_logo.png" className="rounded img-thumbnail logo-partner" alt="Tecnor Logo"/></div>
          </div>
        </div>

        <div className="content-right-col">
          <h2>Contattaci</h2>
          <ContactForm formType="contact"></ContactForm>
        </div>

        <hr className="featurette-divider"></hr>   

      </div>

      <Footer></Footer>
      
    </main>
  </div>
  );
}

export default HomePage;
