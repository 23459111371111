import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import {Navbar, Nav, Container, Row, Col} from 'react-bootstrap';
import Slider from './Slider'
import Title from './Title'

class Header extends Component {

  render() {

    const cpage = this.props.cpage;

    return <header className="App-header">
      <Container>
        <Row className="topAreaHeader">
          <Col className="noBordersElement">
            <Row className="headerWhiteBorder">
              </Row>
              <Row>
                <Col className="socialLinksH"></Col>
                <Col className="socialLinksContainer">
                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <Navbar className="dmnavbar" collapseOnSelect expand="sm" bg="light" variant="light">
                    <Navbar.Brand className="navbar-brand" href="/">
                    <picture className="logo">
                      <source media="(min-width: 1100px)" srcSet="images/logo/diemme_logo_transparent_large.png" />
                      <source media="(min-width: 576px)" srcSet="images/logo/diemme_logo_transparent_medium.png" />
                      <source media="(min-width: 100px)" srcSet="images/logo/diemme_logo_transparent_small.png" />
                      <img src="images/logo/diemme_logo_transparent_large.png" alt="DM Srl" />
                    </picture>
                    </Navbar.Brand> 
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="mr-auto"></Nav>
                      <Nav>
                        <NavLink to="/">Home Page</NavLink>
                        <NavLink to="/servizi">Servizi</NavLink>
                        <NavLink to="/d3link">D3 Link</NavLink>
                        <NavLink to="/contatti">Contatti</NavLink>
                        <NavLink to="/lavora-con-noi">Lavora con noi</NavLink>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </Col>
              </Row>  
          </Col>
        </Row>
        
        <hr/>
        { cpage === 'home' ? <Slider></Slider> : <Title cpage={cpage}></Title>}        
        <hr/>
      </Container>
        
      </header>
  }
}

export default Header; // Don’t forget to use export default!