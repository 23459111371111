import React, { Component } from 'react';
import {Form, Col} from 'react-bootstrap';

class FormItem extends Component {

    constructor(props) {
        super();
        this.state = {
            "formItemKey": `form_${props.formItemName}`,
            "formItemName": props.formItemName,
            "formItemType": props.formItemType,
            "formItemText": props.formItemText,
            "formItemValues": props.formItemValues,
            "formItemCols": props.formItemCols
        };
        this.retFormItem = null;
      }
    
    composeItem() {
        if (this.state.formItemType === "select"){
            const options = this.state.formItemValues.map((itemValue, index) => {
                return <option key={itemValue}>{itemValue}</option>
            })

            this.retFormItem = <Form.Group key={this.state.formItemKey}>
                <Form.Label column sm={12}>
                    {this.state.formItemText}
                </Form.Label>
                <Col sm={12}>
                <Form.Control id={this.state.formItemName} name={this.state.formItemName} as="select">
                    <option value="">Scegli...</option>
                    {options}
                </Form.Control>
                </Col>
                </Form.Group>
        } else if (this.state.formItemType === "textArea" && this.state.formItemCols && Number(this.state.formItemCols) > 0) {
            this.retFormItem = <Form.Group key={this.state.formItemKey}>
                <Form.Label column sm={12}>
                    {this.state.formItemText}
                </Form.Label>
                <Col sm={12}>
                <Form.Control id={this.state.formItemName} name={this.state.formItemName} as="textarea" rows={this.state.formitemCols} />
                </Col>
            </Form.Group>
        } else {
            this.retFormItem = <Form.Group key={this.state.formItemKey}>
                <Form.Label column sm={12}>
                {this.state.formItemText}:
                </Form.Label>
                <Col sm={12}>
                <Form.Control id={this.state.formItemName} name={this.state.formItemName} type={this.state.formItemType} placeholder={this.state.formItemText} />
                </Col>
            </Form.Group>
        }    
        return this.retFormItem
    }

    render() {
        return this.composeItem()            
    }

}

export default FormItem;