import React, { Component } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';


class Footer extends Component {
  componentDidMount(){
    (function (w,d) {
      var loader = function () {
        var s = d.createElement("script"), 
        tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);
      }; 
      if(w.addEventListener){
        w.addEventListener("load", loader, false);
      } else if (w.attachEvent){
        w.attachEvent("onload", loader);
      } else {
        w.onload = loader;
      }
    }
    )(window, document);
  }
  render() {
    return <footer>
      <div className="footer">
        <div className="addressContainer">
            <h2>Dove Siamo</h2>
            <div className="footerElement">
              <div className="footericon"><FontAwesomeIcon color="#ff8401" icon={faMapMarkerAlt} /></div>
              <div className="footericontext">Via delle Industrie, 9/3<br/>20883 Mezzago (MB)</div>
            </div>
            <div className="footerElement">
              <div className="footericon"><FontAwesomeIcon color="#ff8401" icon={faPhoneAlt} /></div>
              <div className="footericontext">+39 039 59.67.042</div>
            </div>
            <div className="footerElement">
              <div className="footericon"><FontAwesomeIcon color="#ff8401" icon={faEnvelope} /></div>
              <div className="footericontext">assistenza@servicedm.it</div>
            </div>
            <div className="footerElement">
              <div className="footericon">&nbsp;</div>
              <div className="footericontext">&nbsp;</div>
            </div>
            <div className="footerElement">
              <div className="footericon">&nbsp;</div>
              <div className="footericontext"><a href="/contatti" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Contattaci</a></div>
            </div>
        </div>
        <div className="mapContainer">
        <iframe title="map" width="100%" height="100%" frameBorder="0" style={{'border':'0'}} src="https://www.google.com/maps/embed/v1/place?q=place_id:Ei9WaWEgZGVsbGUgSW5kdXN0cmllLCA5LCAyMDg4MyBNZXp6YWdvIE1CLCBJdGFseSIwEi4KFAoSCZNPbXLIs4ZHEZyJ8YsNO7BeEAkqFAoSCcMu5vzIs4ZHEclAObQHDbwR&key=AIzaSyC67Dr75KMkp9wjaHq8y5mcVxajC5Ao-gI" allowFullScreen></iframe>
        </div>
      </div>
      <div className="fiscContainer">
        DM s.r.l. - P.I./C.F. 10092520963 - Iscr. REA Cod. 123456 - <a href="https://www.iubenda.com/privacy-policy/78824264" className="iubenda-black iubenda-embed" title="Privacy Policy">Privacy Policy</a>
      </div>
    </footer>
  }
}

export default Footer;