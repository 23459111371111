import React, {Component} from 'react';

import './App.css';
import Header from './components/Header';
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'


class NotFound extends Component {
  
  render(){
    
    return <div className="Contacts">      
      <Header cpage="Errore" />
      <main role="main">

      <div className="mainConent">

        <div className="content-left-col">
          <p className="lead">
            La pagina che cerchi non esiste
            <br/>
            Torna in home page.
          </p>
        </div>

        <div className="content-right-col">
          <h2>Contattaci</h2>
          <ContactForm></ContactForm>
        </div>

        <hr className="featurette-divider"></hr>   

      </div>

      <Footer></Footer>

    </main>
  </div>
  
  }
}

export default NotFound;