import React, {Component} from 'react';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'


class Servizi extends Component {

  render(){
    const marchi = ["ACCUWAY", "AERRE", "ANGELINI", "ARES SEIKI", "AXA", "BENELLI ANNIBALE", "BIGLIA", "BRIDGEPORT", "CASTOR", "CHIRON", "CME", "CMT", "DAEWOO", "DEFUM", "DMG", "DOOSAN", "EMCO", "ERMAKSAN", "FADAL", "FAMUP", "FAVRETTO", "FEELER", "FIL", "GIANA", "GILDEMEINSTER", "HANKOOK", "HARDINGE", "HARTFORD", "HECKERT", "HITACHI", "HONOR", "HULLER HILLE", "HURON", "HYUNDAY", "IMT", "JESSEY", "JUPITER", "KIRA", "KITAMURA", "KRONOS", "L.K MACHINERY", "LEADWEEL", "LEONARD", "LICO", "LILIAN", "MANDELLI", "MARIO CARNAGHI", "MATSUURA", "MAXNOVO", "MIKRON", "MIND MACH T", "MORI SEIKI", "MURATEC WIEDEMANN", "NAKAMURA-TOME", "NIKKEI", "NOVAR", "PADOVANI", "QUASER", "RAFAMET", "REAL MECA", "SACHMAN", "SAMSUNG", "SAPOF", "SIGMA", "SPINNER", "STARWAY", "SUPERMAK", "SUPERMAX", "TAKISAWA", "VARMEC", "VICTOR", "YCM"];  
    return <div className="Contacts">      
      <Header cpage="Servizi" />
      <main role="main">

      <div className="mainConent">

        <div className="content-left-col">
          <p className="lead">
          Il nostro settore maggiormente sviluppato si articola su macchinari a controllo numerico di piccole e medie
          dimensioni come centri di lavoro, torni, caricatori di barre, macchine a taglio laser, punzonatrici ecc. con
          controlli Fanuc, Siemens, Heidenain, Selca, Mitsubishi, Beckoff.
          </p>
          <h2>Il nostro magazzino</h2>
          <p>
          Il nostro magazzino ricambi vanta più di mille articoli a disposizione, pronti alla spedizione il giorno stesso o
          da unire a intervento tecnico. Inoltre forniamo un servizio di riparazione parti o permuta immediata del
          guasto con uno rigenerato specialmente per marchio Siemens nei quali siamo particolarmente forti anche
          nei prezzi nonchè nel possesso di schede obsolete.
          </p>
          <h2>Le nostre competenze</h2>
          <p>
          Siamo in grado di eseguire anche:
          <ul>
            <li>retrofit completo o parziale su controlli, azionamenti o parti meccaniche sia presso il cliente che presso la
            nostra sede.</li>
            <li>upgrade di interfacce robot o spingibarra.</li>
            <li>installazione di sonde di misura con prodotti Renishaw o altre marche.</li>
            <li>applicazione righe ottiche</li>
            <li>applicazione di quarti asse</li>
            <li>corsi di programmazione a bordo macchia presso il cliente o nostra sede.</li>
            <li>esecuzione programma di lavoro</li>
            <li>modifiche PLC</li>
            <li>applicazioni speciali.</li>
            <li>installazione e spostamenti macchinari</li>
          </ul>
          </p>
          <h2>I marchi trattati finora</h2>
          <p>
            <div className="marchiContainer">
            {marchi.map((item) => (
              <div className="marchiItem">{item}</div>
            ))}
            </div>

          </p>
        </div>

        <div className="content-right-col">
          <h2>Gallery</h2>
          <div className="side_image_container">
            <img src="images/pages/magazzino_1.jpg" className="side_image rounded img-thumbnail" alt="Magazzino"></img>
          </div>
          <div className="side_image_caption">
            Il nostro magazzino è sempre fornito
          </div>

          <div className="side_image_container">
            <img src="images/pages/magazzino_2.jpg" className="side_image rounded img-thumbnail" alt="Ricambi"></img>
          </div>
          <div className="side_image_caption">
            Abbiamo sempre il ricambio giusto per ogni intervento
          </div>
        </div>

        <hr className="featurette-divider"></hr>   

      </div>

      <Footer></Footer>

    </main>
  </div>
  
  }
}

export default Servizi;
