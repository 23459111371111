import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FormItem from './formItem'
import DMModal from './modal'

const nodeMailerServiceUrl = process.env.REACT_APP_NODE_MAILER_SERVICEURL;

class ContactForm extends Component {

  constructor(props) {
    super();
    this.state = {
      "formType": props.formType,
      "formFields": {
        "contact": [
          { "companyName": { "text": "Ragione Sociale", "required": false, "type": "text" } },
          { "name": { "text": "Nome", "required": false, "type": "text" } },
          { "surname": { "text": "Cognome", "required": true, "type": "text" } },
          { "mailFrom": { "text": "Email", "required": true, "type": "email" } },
          { "phone": { "text": "Telefono", "required": false, "type": "text" } },
          { "subject": { "text": "Motivo della richiesta", "required": true, "type": "select", "values": ["Assistenza tecnica", "Contatto Commerciale", "Altro"] } },
          { "mailBody": { "text": "Richiesta", "required": true, "type": "textArea", "cols": 3 } }
        ],
        "job": [
          { "name": { "text": "Nome", "required": false, "type": "text" } },
          { "surname": { "text": "Cognome", "required": true, "type": "text" } },
          { "mailFrom": { "text": "Email", "required": true, "type": "email" } },
          { "phone": { "text": "Telefono", "required": false, "type": "text" } },
          { "subject": { "text": "Desidero collaborare nel ruolo", "required": true, "type": "select", "values": ["Collaboratore interno", "Collaboratore esterno"] } },
          { "mailBody": { "text": "Presentazione", "required": true, "type": "textArea", "cols": 3 } }
        ]
      },
      "modalShow": false,
      "modalType": "close",
      "fields": ""
    };

    this.state.currentFormFields = this.state.formType === "Job" ? this.state.formFields.job : this.state.formFields.contact
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setModalShow = (tf, mt, f) => {
    this.setState({"modalShow": tf, "modalType": mt, "fields": f ? f : ""});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const jsonToSend = {};
    const missingFields = [];

    // Accettazione privacy
    if (data.get("privacy") === null) {
      this.setModalShow(true, "missingPrivacy");
      return;
    }

    for (let index = 0; index < this.state.currentFormFields.length; index++) {
      const element = this.state.currentFormFields[index];
      const elementName = Object.keys(element)[0];
      if (data.get(elementName) === "" && element[elementName].required) {
        missingFields.push(element[elementName].text);
      } else {
        jsonToSend[elementName] = { "key": element[elementName].text, "value": data.get(elementName) };
      }
    }
    if (missingFields.length > 0) {
      this.setModalShow(true, "missingFields", missingFields.join(", "));
      return;
    }

    fetch(nodeMailerServiceUrl, {
      method: 'POST',
      body: JSON.stringify(jsonToSend),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then(response => {
      this.setModalShow(true, "success")

    })
  }

  render() {
    return <div><Form key="contactForm" id="contactForm" className="formContainer" onSubmit={this.handleSubmit}>
      {
        this.state.currentFormFields.map((formItem, index) => {
          const formItemName = Object.keys(formItem)
          const { text: formItemText, type: formItemType, values: formItemValues, cols: formItemCols } = formItem[formItemName]
          return <FormItem key={index} formItemName={formItemName} formItemText={formItemText} formItemType={formItemType} formItemValues={formItemValues} formItemCols={formItemCols}></FormItem>
        })
      }


      <Form.Group as={Row} controlId="formCheckPrivacy">
        <Col sm={12}>
          <Form.Check id="privacy" name="privacy" style={{ float: 'left' }} /><span style={{ float: 'left' }}>Ho letto e accetto l'informativa alla <a href="https://www.iubenda.com/privacy-policy/78824264" style={{ color: 'orange' }} target="_blank" rel="noopener noreferrer">Privacy</a></span>
        </Col>
        <Col sm={12} className="formSubmitContainer">
          <Button type="submit">Invia</Button>
        </Col>
      </Form.Group>
    </Form>

    <DMModal show={this.state.modalShow} modaltype={this.state.modalType} fields={this.state.fields} />
      
    </div>
  }
}

export default ContactForm;